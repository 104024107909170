import { buildProperty } from '@camberi/firecms'

export type ContactInterface = {
  site: string
  date: Date
  name: string
  email: string
  phone: any
  msg: string
  datum: string
  time: string
  where: string
  handled: boolean
}

const getContactProperties = () => {
  const ContactProperties: any = {
    name: 'Aanvragen',
    singularName: 'Aanvraag',
    description: `Aanvragen via de websites`,
    path: `/contact`,
    group: 'Algemeen',
    defaultSize: 'm',
    initialSort: ['date', 'desc'],

    // permissions: ({ authController }: any) => ({
    //   edit: true,
    //   create: true,
    //   delete: false
    // }),

    properties: {
      site: {
        name: 'Site',
        dataType: 'string',
        validation: { required: false }
      },
      date: {
        name: 'Datum',
        dataType: 'date',
        validation: { required: false },
        mode: 'date'
      },
      name: {
        name: 'Naam',
        dataType: 'string',
        validation: { required: false }
      },
      email: {
        name: 'Email',
        dataType: 'string',
        validation: { required: false }
      },
      phone: {
        name: 'Telefoonnr',
        dataType: 'string',
        validation: { required: false }
      },
      msg: {
        name: 'Bericht',
        dataType: 'string',
        markdown: true,
        validation: { required: false }
      },
      datum: {
        name: 'Datum',
        dataType: 'string',
        validation: { required: false }
      },
      time: {
        name: 'Tijd',
        dataType: 'string',
        validation: { required: false }
      },
      where: {
        name: 'Waar',
        dataType: 'string',
        validation: { required: false }
      },
      handled: {
        name: 'Afgehandeld?',
        dataType: 'boolean',
        defaultValue: false
      }
    }
  }

  return ContactProperties
}

export { getContactProperties }
