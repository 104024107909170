import { buildProperty } from '@camberi/firecms'

export type PageInterface = {
  publish: boolean
  order: number
  title: string
  type: string
  img: string
  pageContent: any
  slug: string
}

export type inputType = {
  name: string
  path: string
  group: string
}

const getPageProperties = (params: inputType) => {
  const gr = params.group.charAt(0).toUpperCase() + params.group.slice(1)

  const PageProperties: any = {
    name: params.name,
    singularName: params.name,
    description: `${gr} - ${params.name}`,
    path: `${params.group}_${params.path}`,
    group: gr,
    defaultSize: 'm',
    initialSort: ['type', 'asc'],

    permissions: ({ authController }: any) => ({
      edit: true,
      create: true,
      delete: false
    }),

    properties: {
      publish: {
        name: 'Publiceren?',
        validation: { required: true },
        dataType: 'boolean',
        defaultValue: true
      },
      order: {
        name: 'Volgorde',
        validation: { required: true },
        dataType: 'number',
        defaultValue: 0
      },
      title: buildProperty({
        name: 'Titel',
        validation: { required: false },
        dataType: 'string'
      }),

      heroSlogan: buildProperty({
        name: 'Hero Slogan',
        validation: { required: false },
        dataType: 'string'
      }),

      heroText: buildProperty({
        name: 'Hero Tekst',
        validation: { required: false },
        dataType: 'string',
        markdown: true,
        columnWidth: 300
      }),

      type: buildProperty({
        name: 'type',
        validation: { required: false },
        dataType: 'string',
        enumValues: {
          homepage: 'Homepage',
          homepageAbout: 'Homepage Over Ons',
          homepageConcept: 'Homepage Concept',
          homepagePrices: 'Homepage Prijzen',
          concept: 'Concept',
          informatie: 'Informatie',
          product: 'Product'
        }
      }),

      img: buildProperty({
        name: 'Afbeelding',
        dataType: 'string',
        storage: {
          storeUrl: true,
          storagePath: `images/${params.group}/${params.path}`,
          acceptedFiles: ['image/*']
        }
      }),

      pageContent: buildProperty({
        name: 'Content',
        validation: { required: false },
        dataType: 'array',
        of: {
          dataType: 'map',
          name: 'Pagina onderdeel',
          validation: { required: false },
          properties: {
            color: {
              name: 'Kleur',
              validation: { required: true },
              defaultValue: 'clear',
              dataType: 'string',
              enumValues: {
                dark: 'Donker',
                medium: 'Lichtgrijs',
                clear: 'Wit'
              }
            },
            title: {
              name: 'Titel',
              validation: { required: false },
              dataType: 'string'
            },
            paragraph: {
              name: 'Paragraaf',
              dataType: 'string',
              markdown: true,
              columnWidth: 300
            },

            list: {
              name: 'Vervolg Lijst',
              validation: { required: false },
              dataType: 'string',
              defaultValue: 'nolist',
              enumValues: {
                nolist: 'Geen',
                sneakers: 'Sneakers',
                concept: 'Concept tegels',
                models: 'Modellen',
                materials: 'Materialen',
                belts: 'Riemen',
                buckles: 'Gespen',
                customs: 'Customs'
              }
            },

            img: buildProperty({
              name: 'Afbeelding',
              dataType: 'string',
              storage: {
                storeUrl: true,
                storagePath: `images/${params.group}/${params.path}`,
                acceptedFiles: ['image/!*']
              }
            })
          }
        }
      }),

      slug: {
        name: 'Slug',
        validation: { required: false },
        dataType: 'string',
        disabled: false
      }
    }
  }

  return PageProperties
}

export { getPageProperties }
